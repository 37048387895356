





































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getpageComment } from '@/api'
import moment from 'moment'
@Component({
  name: 'Dynamic',
  filters: {
    dateFormat: (value: any) => {
      if (
        moment(value).format('YYYY-MM-DD') <
        moment(new Date()).format('YYYY-MM-DD')
      ) {
        return moment(value).format('MM-DD HH:mm')
      } else if (
        moment(value).format('YYYY') > moment(new Date()).format('YYYY')
      ) {
        return moment(value).format('YYYY-MM-DD HH:mm')
      } else {
        return moment(value).format('HH:mm')
      }
    }
  }
})
export default class Dynamic extends Vue {
  [x: string]: any
  // 是否显示弹窗
  @Prop({
    type: Object
  })
  ruleForm!: any

  @Prop({
    type: String,
    default: 'ug'
  })
  language!: string

  buleImg = require('@/assets/slice-bule.png')
  yellowImg = require('@/assets/slice-yellow.png')

  // 评论列表
  commentList: any = []

  emptyUrl = require('@/assets/Empty_NoData.png')

  // 是否处在加载状态
  loading = false

  // 是否已加载完成
  finished = false

  // 是否加载失败
  error = false

  // 分页
  pageNum = 1

  // 每页条数
  pageSize = 20

  // 数据总条数
  total = 0

  created() {
    this.getpageComment()
  }

  // 获取列表数据
  async getpageComment() {
    const { data } = await getpageComment({
      pageNum: this.pageNum,
      pageSize: this.pageSize,
      updatesId: this.$route.query.updatesId
    })
    if (data.list.length == 0 || data.list == null) {
      // 判断获取数据条数若等于0
      this.commentList = [] // 清空数组
      this.finished = true // 停止加载
    }
    // 若数据条数不等于0
    this.total = data.total // 给数据条数赋值
    this.commentList.push(...data.list) // 将数据放入list中
    this.loading = false // 加载状态结束
    // 如果list长度大于等于总数据条数,数据全部加载完成
    if (this.commentList.length >= data.total) {
      this.finished = true // 结束加载状态
    }
  }

  // 被 @load调用的方法
  onLoad() {
    this.getpageComment() // 调用上面方法,请求数据
    this.pageNum++ // 分页数加一
    this.finished = false
  }
  // 加载失败调用方法
  onRefresh() {
    this.finished = false // 清空列表数据
    this.loading = true // 将 loading 设置为 true，表示处于加载状态
    this.pageNum = 1 // 分页数赋值为1
    this.commentList = [] // 清空数组
    this.onLoad() // 重新加载数据
  }
}
