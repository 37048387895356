





































































import { Component, Vue } from 'vue-property-decorator'
import { LoyoutModule } from '@/store/modules/layout'
import { getUpdates } from '@/api'
import Dynamic from './components/Dynamic.vue'
import Topic from './components/Topic.vue'
import { getPlatform } from '@/utils'
import { CommonModule } from '@/store/modules/common'
@Component({
  name: 'DynamicIndex',
  components: {
    Dynamic,
    Topic
  }
})
export default class DynamicIndex extends Vue {
  ruleForm: any = {}

  emptyUrl = require('@/assets/Empty_NoData.png')

  isGuidance = false

  pla = getPlatform()

  isContent = false

  buleImg = require('@/assets/slice-bule.png')
  yellowImg = require('@/assets/slice-yellow.png')

  created() {
    // 获取当前语言

    LoyoutModule.setTitle(this.$t('动态'))
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }
  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language || 'ug'
  }
  mounted() {
    this.getUpdates()
  }
  // 获取动态详情
  async getUpdates() {
    const { data, code } = await getUpdates({
      updatesId: this.$route.query.updatesId,
      userId: 0
    })
    if (code == 1001) {
      this.ruleForm = { ...data }
      this.isContent = true
    }
  }

  // 下载
  handelDownload() {
    if (this.pla === 'weixin' || this.pla === 'qq') {
      this.isGuidance = true
    } else {
      this.isGuidance = false
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        const loadDateTime: any = new Date()
        window.location.href = 'allo.ios.app://' //schema链接或者universal link
        window.setTimeout(function() {
          //如果没有安装app,便会执行setTimeout跳转下载页
          const timeOutDateTime: any = new Date()
          if (timeOutDateTime - loadDateTime < 5000) {
            window.location.href = 'https://itunes.apple.com/app/1664635935' //ios下载页面
          } else {
            window.close()
          }
        }, 500)
      } else if (navigator.userAgent.match(/android/i)) {
        try {
          window.location.href = 'allo://tel.top/home/callshow?select=2' //schema链接或者universal link
          window.setTimeout(function() {
            const u = navigator.userAgent
            if (u.match(/(vivo|VIVO);?/i)) {
              window.location.href = 'vivomarket://details?id=com.allo.contacts' // vivo手机
            } else if (u.match(/(OPPO|oppo);?/i)) {
              window.location.href =
                'oppomarket://details?packagename=com.allo.contacts' // oppo手机
            } else if (u.match(/(HUAWEI|HONOR);?/i)) {
              window.location.href = 'appmarket://details?id=com.allo.contacts' // 华为手机
            } else if (u.match(/(XIAOMI|redmi);?/i)) {
              window.location.href = 'mimarket://details?id=com.allo.contacts' //小米手机
            } else {
              //安卓其他设备
              window.location.href = process.env.VUE_APP_ALLO // 下载地址
            }
          }, 500)
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
}
